import { DateLocalizer } from 'react-big-calendar/lib/localizer'
import { getMonth, format, parse, startOfWeek, getDay } from 'date-fns'

// Helper function to replace dates.eq from the library
const datesAreInSameMonth = (date1, date2) => getMonth(date1) === getMonth(date2)

const dateRangeFormat = ({ start, end }, culture, local) => (
  `${local.format(start, 'P', culture)} – ${local.format(end, 'P', culture)}`)

const timeRangeFormat = ({ start, end }, culture, local) => (
  `${local.format(start, 'p', culture)} – ${local.format(end, 'p', culture)}`)

const timeRangeStartFormat = ({ start }, culture, local) => (
  `${local.format(start, 'h:mma', culture)} – `)

const timeRangeEndFormat = ({ end }, culture, local) => (
  ` – ${local.format(end, 'h:mma', culture)}`)

const weekRangeFormat = ({ start, end }, culture, local) => (
  `${local.format(start, 'MMMM dd', culture)} – ${local.format(
    end,
    datesAreInSameMonth(start, end) ? 'dd' : 'MMMM dd',
    culture
  )}`)

export const formats = {
  dateFormat: 'dd',
  dayFormat: 'dd eee',
  weekdayFormat: 'cccc',

  selectRangeFormat: timeRangeFormat,
  eventTimeRangeFormat: timeRangeFormat,
  eventTimeRangeStartFormat: timeRangeStartFormat,
  eventTimeRangeEndFormat: timeRangeEndFormat,

  timeGutterFormat: 'p',

  monthHeaderFormat: 'MMMM yyyy',
  dayHeaderFormat: 'cccc MMM dd',
  dayRangeHeaderFormat: weekRangeFormat,
  agendaHeaderFormat: dateRangeFormat,

  agendaDateFormat: 'ccc MMM dd',
  agendaTimeFormat: 'p',
  agendaTimeRangeFormat: timeRangeFormat
}

const dateFnsLocalizer = () => {
  return new DateLocalizer({
    formats,
    firstOfWeek(culture) {
      return getDay(startOfWeek(new Date(), { locale: culture }))
    },

    format(value, formatString, culture) {
      return format(new Date(value), formatString, { locale: culture })
    },
  })
}

export default dateFnsLocalizer
